








































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import moment from "moment";

export default Vue.extend({
  name: "ma-time-picker",
  props: {
    value: String,
    label: String,
    timePickerProps: Object,
    editable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      menu: false,
      mValue: "",
      rules,
      timeapppoint: "",
    };
  },
  computed: {
    textFieldValue(): string {
      const value = moment(this.mValue, "hh:mm");

      if (value.isValid() == false) return "";
      return value.format("hh:mm a");
    },
  },
  watch: {
    value(val: string) {
      if (this.editable) {
        this.timeapppoint = val;
      } else {
        this.mValue = val;
      }
    },
    menu(show: boolean) {
      if (show == false) {
        this.saveMenu();
      }
    },
    timeapppoint: {
      handler() {
        (this as any).saveTime();
      },
    },
  },
  methods: {
    saveTime() {
      if ((this as any).timeapppoint != "") {
        this.$emit("input", (this as any).timeapppoint);
      } else {
        this.$emit("input", null);
      }
    },
    resetTime() {
      (this as any).timeapppoint = "";
    },
    saveMenu() {
      (this as any).$refs.menu.save(this.mValue);
      this.$emit("input", this.mValue);
    },
  },
});
